<template>
  <div class="page__wrapper">
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        第一部分：认证二手车业务
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        <el-button
          v-show="editable"
          type="primary"
          style="float: right;"
          @click="handleSaveClick('1')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>

    <ag-grid
      ref="nvrGrid"
      style="width: 100%; height: 2240px"
      :column-defs="columnDefs1"
      :row-data.sync="tableData1"
      :grid-options="gridOptions1"
    />
    <br>
    <el-row>
      <el-col
        :span="24"
        class="table-period"
      >
        <el-button
          v-show="editable"
          type="primary"
          style="float: right;"
          @click="handleSaveClick('2')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="nvrGrid"
      style="width: 100%; height: 616px"
      :column-defs="columnDefs2"
      :row-data.sync="tableData2"
      :grid-options="gridOptions2"
    />
    <br>
    <el-row>
      <el-col
        :span="24"
        class="table-period"
      >
        <el-button
          v-show="editable"
          type="primary"
          style="float: right;"
          @click="handleSaveClick('3')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="nvrGrid"
      style="width: 100%; height: 168px"
      :column-defs="columnDefs3"
      :row-data.sync="tableData3"
      :grid-options="gridOptions3"
    />
    <br>
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        第二部分：非认证/非本品二手车处置业务
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        <el-button
          v-show="editable"
          type="primary"
          style="float: right;"
          @click="handleSaveClick('4')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="nvrGrid"
      style="width: 100%; height: 196px"
      :column-defs="columnDefs4"
      :row-data.sync="tableData4"
      :grid-options="gridOptions4"
    />
    <br>
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        第三部分：二手车衍生业务
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        <el-button
          v-show="editable"
          type="primary"
          style="float: right;"
          @click="handleSaveClick('5')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="nvrGrid"
      style="width: 100%; height: 252px"
      :column-defs="columnDefs5"
      :row-data.sync="tableData5"
      :grid-options="gridOptions5"
    />
    <br>
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        第四部分：二手车库存情况统计表
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        <el-button
          v-show="editable"
          type="primary"
          style="float: right;"
          @click="handleSaveClick('6')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="nvrGrid"
      style="width: 100%; height: 2913px"
      :column-defs="columnDefs6"
      :row-data.sync="tableData6"
      :grid-options="gridOptions6"
    />
  </div>
</template>

<script>
import Axios from 'axios'
const BASEURL = {
  list1: '/jlr/tt/usedCarData1/list',
  list2: '/jlr/tt/usedCarData2/list',
  list3: '/jlr/tt/usedCarData3/list',
  // list4: '/tt/usedCarData3/list'
  list5: '/jlr/tt/usedCarData4/list',
  list6: '/jlr/tt/usedCarData5/list',
  update1: '/jlr/tt/usedCarData1/batch/update',
  update2: '/jlr/tt/usedCarData2/batch/update',
  update3: '/jlr/tt/usedCarData3/batch/update',
  update4: '/jlr/tt/usedCarData3/batch/update',
  update5: '/jlr/tt/usedCarData4/batch/update',
  update6: '/jlr/tt/usedCarData5/batch/update'
}

export default {
  name: 'UsedCarBusiness',
  props: {
    editable: { type: Boolean, default: true },
    detailData: { type: Object },
    headInfo: { type: Object }
  },
  data () {
    return {
      isShowDetailDialog: false,
      gridOptions1: {},
      gridOptions2: {},
      gridOptions3: {},
      gridOptions4: {},
      gridOptions5: {},
      gridOptions6: {},
      tableData1: [
        {
          carType: 'L550',
          carAge: '0 - 1年'
        },
        {
          carType: 'L550',
          carAge: '1 - 3年'
        },
        {
          carType: 'L550',
          carAge: '3 - 5年'
        },
        {
          carType: 'L550',
          carAge: '5年以上'
        },
        {
          carType: 'L538',
          carAge: '0 - 1年'
        },
        {
          carType: 'L538',
          carAge: '1 - 3年'
        },
        {
          carType: 'L538',
          carAge: '3 - 5年'
        },
        {
          carType: 'L538',
          carAge: '5年以上'
        },
        {
          carType: 'L551',
          carAge: '0 - 1年'
        },
        {
          carType: 'L551',
          carAge: '1 - 3年'
        },
        {
          carType: 'L551',
          carAge: '3 - 5年'
        },
        {
          carType: 'L551',
          carAge: '5年以上'
        },
        {
          carType: 'Defender',
          carAge: '0 - 1年'
        },
        {
          carType: 'Defender',
          carAge: '1 - 3年'
        },
        {
          carType: 'Defender',
          carAge: '3 - 5年'
        },
        {
          carType: 'Defender',
          carAge: '5年以上'
        },
        {
          carType: 'New Discovery',
          carAge: '0 - 1年'
        },
        {
          carType: 'New Discovery',
          carAge: '1 - 3年'
        },
        {
          carType: 'New Discovery',
          carAge: '3 - 5年'
        },
        {
          carType: 'New Discovery',
          carAge: '5年以上'
        },
        {
          carType: 'Evoque Convertible',
          carAge: '0 - 1年'
        },
        {
          carType: 'Evoque Convertible',
          carAge: '1 - 3年'
        },
        {
          carType: 'Evoque Convertible',
          carAge: '3 - 5年'
        },
        {
          carType: 'Evoque Convertible',
          carAge: '5年以上'
        },
        {
          carType: 'Velar',
          carAge: '0 - 1年'
        },
        {
          carType: 'Velar',
          carAge: '1 - 3年'
        },
        {
          carType: 'Velar',
          carAge: '3 - 5年'
        },
        {
          carType: 'Velar',
          carAge: '5年以上'
        },
        {
          carType: 'RRS',
          carAge: '0 - 1年'
        },
        {
          carType: 'RRS',
          carAge: '1 - 3年'
        },
        {
          carType: 'RRS',
          carAge: '3 - 5年'
        },
        {
          carType: 'RRS',
          carAge: '5年以上'
        },
        {
          carType: 'RR',
          carAge: '0 - 1年'
        },
        {
          carType: 'RR',
          carAge: '1 - 3年'
        },
        {
          carType: 'RR',
          carAge: '3 - 5年'
        },
        {
          carType: 'RR',
          carAge: '5年以上'
        },
        {
          carType: 'E-PACE',
          carAge: '0 - 1年'
        },
        {
          carType: 'E-PACE',
          carAge: '1 - 3年'
        },
        {
          carType: 'E-PACE',
          carAge: '3 - 5年'
        },
        {
          carType: 'E-PACE',
          carAge: '5年以上'
        },
        {
          carType: 'XFL',
          carAge: '0 - 1年'
        },
        {
          carType: 'XFL',
          carAge: '1 - 3年'
        },
        {
          carType: 'XFL',
          carAge: '3 - 5年'
        },
        {
          carType: 'XFL',
          carAge: '5年以上'
        },
        {
          carType: 'XEL',
          carAge: '0 - 1年'
        },
        {
          carType: 'XEL',
          carAge: '1 - 3年'
        },
        {
          carType: 'XEL',
          carAge: '3 - 5年'
        },
        {
          carType: 'XEL',
          carAge: '5年以上'
        },
        {
          carType: 'XE',
          carAge: '0 - 1年'
        },
        {
          carType: 'XE',
          carAge: '1 - 3年'
        },
        {
          carType: 'XE',
          carAge: '3 - 5年'
        },
        {
          carType: 'XE',
          carAge: '5年以上'
        },
        {
          carType: 'XF',
          carAge: '0 - 1年'
        },
        {
          carType: 'XF',
          carAge: '1 - 3年'
        },
        {
          carType: 'XF',
          carAge: '3 - 5年'
        },
        {
          carType: 'XF',
          carAge: '5年以上'
        },
        {
          carType: 'XJ',
          carAge: '0 - 1年'
        },
        {
          carType: 'XJ',
          carAge: '1 - 3年'
        },
        {
          carType: 'XJ',
          carAge: '3 - 5年'
        },
        {
          carType: 'XJ',
          carAge: '5年以上'
        },
        {
          carType: 'F-Type',
          carAge: '0 - 1年'
        },
        {
          carType: 'F-Type',
          carAge: '1 - 3年'
        },
        {
          carType: 'F-Type',
          carAge: '3 - 5年'
        },
        {
          carType: 'F-Type',
          carAge: '5年以上'
        },
        {
          carType: 'F-Pace',
          carAge: '0 - 1年'
        },
        {
          carType: 'F-Pace',
          carAge: '1 - 3年'
        },
        {
          carType: 'F-Pace',
          carAge: '3 - 5年'
        },
        {
          carType: 'F-Pace',
          carAge: '5年以上'
        },
        {
          carType: 'I-Pace',
          carAge: '0 - 1年'
        },
        {
          carType: 'I-Pace',
          carAge: '1 - 3年'
        },
        {
          carType: 'I-Pace',
          carAge: '3 - 5年'
        },
        {
          carType: 'I-Pace',
          carAge: '5年以上'
        },
        {
          carType: '除试驾车/代步车以外的认证二手车小计',
          carAge: '0 - 1年'
        },
        {
          carType: '除试驾车/代步车以外的认证二手车小计',
          carAge: '1 - 3年'
        },
        {
          carType: '除试驾车/代步车以外的认证二手车小计',
          carAge: '3 - 5年'
        },
        {
          carType: '除试驾车/代步车以外的认证二手车小计',
          carAge: '5年以上'
        },
        {
          carType: '除试驾车/代步车以外的认证二手车小计',
          carAge: '合计'
        }
      ],
      tableData2: [
        {
          carType: 'L550'
        },
        {
          carType: 'L538'
        },
        {
          carType: 'L551'
        },
        {
          carType: 'Defender'
        },
        {
          carType: 'New Discovery'
        },
        {
          carType: 'Evoque Convertible'
        },
        {
          carType: 'Velar'
        },
        {
          carType: 'RRS'
        },
        {
          carType: 'RR'
        },
        {
          carType: 'E-PACE'
        },
        {
          carType: 'XFL'
        },
        {
          carType: 'XEL'
        },
        {
          carType: 'XE'
        },
        {
          carType: 'XF'
        },
        {
          carType: 'XJ'
        },
        {
          carType: 'F-Type'
        },
        {
          carType: 'F-Pace'
        },
        {
          carType: 'I-Pace'
        },
        {
          carType: '试驾车小计'
        }
      ],
      tableData3: [
        {
          brand: '路虎品牌合计',
          type: '4.1.3'
        },
        {
          brand: '捷豹品牌合计',
          type: '4.1.3'
        },
        {
          brand: '代步车小计',
          type: '4.1.3'
        }
      ],
      tableData4: [
        {
          brand: '路虎品牌合计',
          type: '4.2.1'
        },
        {
          brand: '捷豹品牌合计',
          type: '4.2.1'
        },
        {
          brand: '其他品牌',
          type: '4.2.1'
        },
        {
          brand: '合计',
          type: '4.2.1'
        }
      ],
      tableData5: [
        {
          brand: '认证二手车-路虎'
        },
        {
          brand: '认证二手车-捷豹'
        },
        {
          brand: '非认证二手车-路虎'
        },
        {
          brand: '非认证二手车-捷豹'
        },
        {
          brand: '非认证二手车-其他品牌'
        },
        {
          brand: '合计'
        }
      ],
      tableData6: [
        {
          brand: 'L550',
          inventoryDays: '0-30天'
        },
        {
          brand: 'L550',
          inventoryDays: '31-60天'
        },
        {
          brand: 'L550',
          inventoryDays: '61-90天'
        },
        {
          brand: 'L550',
          inventoryDays: '91-180天'
        },
        {
          brand: 'L550',
          inventoryDays: '大于180天'
        },
        {
          brand: 'L538',
          inventoryDays: '0-30天'
        },
        {
          brand: 'L538',
          inventoryDays: '31-60天'
        },
        {
          brand: 'L538',
          inventoryDays: '61-90天'
        },
        {
          brand: 'L538',
          inventoryDays: '91-180天'
        },
        {
          brand: 'L538',
          inventoryDays: '大于180天'
        },
        {
          brand: 'L551',
          inventoryDays: '0-30天'
        },
        {
          brand: 'L551',
          inventoryDays: '31-60天'
        },
        {
          brand: 'L551',
          inventoryDays: '61-90天'
        },
        {
          brand: 'L551',
          inventoryDays: '91-180天'
        },
        {
          brand: 'L551',
          inventoryDays: '大于180天'
        },
        {
          brand: 'Defender',
          inventoryDays: '0-30天'
        },
        {
          brand: 'Defender',
          inventoryDays: '31-60天'
        },
        {
          brand: 'Defender',
          inventoryDays: '61-90天'
        },
        {
          brand: 'Defender',
          inventoryDays: '91-180天'
        },
        {
          brand: 'Defender',
          inventoryDays: '大于180天'
        },
        {
          brand: 'New Discovery',
          inventoryDays: '0-30天'
        },
        {
          brand: 'New Discovery',
          inventoryDays: '31-60天'
        },
        {
          brand: 'New Discovery',
          inventoryDays: '61-90天'
        },
        {
          brand: 'New Discovery',
          inventoryDays: '91-180天'
        },
        {
          brand: 'New Discovery',
          inventoryDays: '大于180天'
        },
        {
          brand: 'Evoque Convertible',
          inventoryDays: '0-30天'
        },
        {
          brand: 'Evoque Convertible',
          inventoryDays: '31-60天'
        },
        {
          brand: 'Evoque Convertible',
          inventoryDays: '61-90天'
        },
        {
          brand: 'Evoque Convertible',
          inventoryDays: '91-180天'
        },
        {
          brand: 'Evoque Convertible',
          inventoryDays: '大于180天'
        },
        {
          brand: 'Velar',
          inventoryDays: '0-30天'
        },
        {
          brand: 'Velar',
          inventoryDays: '31-60天'
        },
        {
          brand: 'Velar',
          inventoryDays: '61-90天'
        },
        {
          brand: 'Velar',
          inventoryDays: '91-180天'
        },
        {
          brand: 'Velar',
          inventoryDays: '大于180天'
        },
        {
          brand: 'RRS',
          inventoryDays: '0-30天'
        },
        {
          brand: 'RRS',
          inventoryDays: '31-60天'
        },
        {
          brand: 'RRS',
          inventoryDays: '61-90天'
        },
        {
          brand: 'RRS',
          inventoryDays: '91-180天'
        },
        {
          brand: 'RRS',
          inventoryDays: '大于180天'
        },
        {
          brand: 'RR',
          inventoryDays: '0-30天'
        },
        {
          brand: 'RR',
          inventoryDays: '31-60天'
        },
        {
          brand: 'RR',
          inventoryDays: '61-90天'
        },
        {
          brand: 'RR',
          inventoryDays: '91-180天'
        },
        {
          brand: 'RR',
          inventoryDays: '大于180天'
        },
        {
          brand: 'E-PACE',
          inventoryDays: '0-30天'
        },
        {
          brand: 'E-PACE',
          inventoryDays: '31-60天'
        },
        {
          brand: 'E-PACE',
          inventoryDays: '61-90天'
        },
        {
          brand: 'E-PACE',
          inventoryDays: '91-180天'
        },
        {
          brand: 'E-PACE',
          inventoryDays: '大于180天'
        },
        {
          brand: 'XFL',
          inventoryDays: '0-30天'
        },
        {
          brand: 'XFL',
          inventoryDays: '31-60天'
        },
        {
          brand: 'XFL',
          inventoryDays: '61-90天'
        },
        {
          brand: 'XFL',
          inventoryDays: '91-180天'
        },
        {
          brand: 'XFL',
          inventoryDays: '大于180天'
        },
        {
          brand: 'XEL',
          inventoryDays: '0-30天'
        },
        {
          brand: 'XEL',
          inventoryDays: '31-60天'
        },
        {
          brand: 'XEL',
          inventoryDays: '61-90天'
        },
        {
          brand: 'XEL',
          inventoryDays: '91-180天'
        },
        {
          brand: 'XEL',
          inventoryDays: '大于180天'
        },
        {
          brand: 'XE',
          inventoryDays: '0-30天'
        },
        {
          brand: 'XE',
          inventoryDays: '31-60天'
        },
        {
          brand: 'XE',
          inventoryDays: '61-90天'
        },
        {
          brand: 'XE',
          inventoryDays: '91-180天'
        },
        {
          brand: 'XE',
          inventoryDays: '大于180天'
        },
        {
          brand: 'XF',
          inventoryDays: '0-30天'
        },
        {
          brand: 'XF',
          inventoryDays: '31-60天'
        },
        {
          brand: 'XF',
          inventoryDays: '61-90天'
        },
        {
          brand: 'XF',
          inventoryDays: '91-180天'
        },
        {
          brand: 'XF',
          inventoryDays: '大于180天'
        },
        {
          brand: 'XJ',
          inventoryDays: '0-30天'
        },
        {
          brand: 'XJ',
          inventoryDays: '31-60天'
        },
        {
          brand: 'XJ',
          inventoryDays: '61-90天'
        },
        {
          brand: 'XJ',
          inventoryDays: '91-180天'
        },
        {
          brand: 'XJ',
          inventoryDays: '大于180天'
        },

        {
          brand: 'F-Type',
          inventoryDays: '0-30天'
        },
        {
          brand: 'F-Type',
          inventoryDays: '31-60天'
        },
        {
          brand: 'F-Type',
          inventoryDays: '61-90天'
        },
        {
          brand: 'F-Type',
          inventoryDays: '91-180天'
        },
        {
          brand: 'F-Type',
          inventoryDays: '大于180天'
        },

        {
          brand: 'F-Pace',
          inventoryDays: '0-30天'
        },
        {
          brand: 'F-Pace',
          inventoryDays: '31-60天'
        },
        {
          brand: 'F-Pace',
          inventoryDays: '61-90天'
        },
        {
          brand: 'F-Pace',
          inventoryDays: '91-180天'
        },
        {
          brand: 'F-Pace',
          inventoryDays: '大于180天'
        },

        {
          brand: 'I-Pace',
          inventoryDays: '0-30天'
        },
        {
          brand: 'I-Pace',
          inventoryDays: '31-60天'
        },
        {
          brand: 'I-Pace',
          inventoryDays: '61-90天'
        },
        {
          brand: 'I-Pace',
          inventoryDays: '91-180天'
        },
        {
          brand: 'I-Pace',
          inventoryDays: '大于180天'
        },

        {
          brand: '非捷豹路虎品牌',
          inventoryDays: '0-30天'
        },
        {
          brand: '非捷豹路虎品牌',
          inventoryDays: '31-60天'
        },
        {
          brand: '非捷豹路虎品牌',
          inventoryDays: '61-90天'
        },
        {
          brand: '非捷豹路虎品牌',
          inventoryDays: '91-180天'
        },
        {
          brand: '非捷豹路虎品牌',
          inventoryDays: '大于180天'
        },
        {
          brand: '二手车库存合计',
          inventoryDays: '0-30天'
        },
        {
          brand: '二手车库存合计',
          inventoryDays: '31-60天'
        },
        {
          brand: '二手车库存合计',
          inventoryDays: '61-90天'
        },
        {
          brand: '二手车库存合计',
          inventoryDays: '91-180天'
        },
        {
          brand: '二手车库存合计',
          inventoryDays: '大于180天'
        },
        {
          brand: '二手车库存合计',
          inventoryDays: '合计'
        }
      ]
    }
  },
  computed: {
    columnDefs1 () {
      return [
        {
          headerName: this.$t('dealer_finance.usedCarBusiness.title411'),
          field: 'tableTitle',
          children: [
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.carType'),
              field: 'carType',
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.carAge'),
              field: 'carAge',
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.numOfSets'),
              field: 'numOfSets',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.numOfSets, ''),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.income'),
              field: 'income',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.income, ''),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.grossProfit'),
              field: 'grossProfit',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.grossProfit, ''),
              minWidth: 200
            }
          ]
        }
      ]
    },
    columnDefs2 () {
      return [
        {
          headerName: this.$t('dealer_finance.usedCarBusiness.title412'),
          field: 'tableTitle',
          children: [
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.carType'),
              field: 'carType',
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.numOfSets'),
              field: 'numOfSets',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.numOfSets, ''),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.income'),
              field: 'income',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.income, ''),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.grossProfit'),
              field: 'grossProfit',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.grossProfit, ''),
              minWidth: 200
            }
          ]
        }
      ]
    },
    columnDefs3 () {
      return [
        {
          headerName: this.$t('dealer_finance.usedCarBusiness.title413'),
          field: 'tableTitle',
          children: [
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.brand'),
              field: 'brand',
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.numOfSets'),
              field: 'numOfSets',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.numOfSets, ''),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.income'),
              field: 'income',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.income, ''),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.grossProfit'),
              field: 'grossProfit',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.grossProfit, ''),
              minWidth: 200
            }
          ]
        }
      ]
    },
    columnDefs4 () {
      return [
        {
          headerName: this.$t('dealer_finance.usedCarBusiness.title421'),
          field: 'tableTitle',
          children: [
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.brand'),
              field: 'brand',
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.numOfSets'),
              field: 'numOfSets',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.numOfSets, ''),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.income'),
              field: 'income',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.income, ''),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.grossProfit'),
              field: 'grossProfit',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.grossProfit, ''),
              minWidth: 200
            }
          ]
        }
      ]
    },
    columnDefs5 () {
      return [
        {
          headerName: this.$t('dealer_finance.usedCarBusiness.title431'),
          field: 'tableTitle',
          children: [
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.brand'),
              field: 'brand',
              minWidth: 200
            },
            {
              headerName: this.$t(
                'dealer_finance.usedCarBusiness.insuranceNumOfSets'
              ),
              field: 'insuranceNumOfSets',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.insuranceNumOfSets, ''),
              minWidth: 200
            },
            {
              headerName: this.$t(
                'dealer_finance.usedCarBusiness.insuranceGrossProfit'
              ),
              field: 'insuranceGrossProfit',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.insuranceGrossProfit, ''),
              minWidth: 200
            },
            {
              headerName: this.$t(
                'dealer_finance.usedCarBusiness.financeNumOfSets'
              ),
              field: 'financeNumOfSets',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.financeNumOfSets, ''),
              minWidth: 200
            },
            {
              headerName: this.$t(
                'dealer_finance.usedCarBusiness.financeGrossProfit'
              ),
              field: 'financeGrossProfit',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.financeGrossProfit, ''),
              minWidth: 200
            },
            {
              headerName: this.$t(
                'dealer_finance.usedCarBusiness.boutiquesSaleIncome'
              ),
              field: 'boutiquesSaleIncome',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.boutiquesSaleIncome, ''),
              minWidth: 200
            },
            {
              headerName: this.$t(
                'dealer_finance.usedCarBusiness.boutiqueSaleCost'
              ),
              field: 'boutiqueSaleCost',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.boutiqueSaleCost, ''),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.otherIncome'),
              field: 'otherIncome',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.otherIncome, ''),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.otherCost'),
              field: 'otherCost',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.otherCost, ''),
              minWidth: 200
            },
            {
              headerName: this.$t(
                'dealer_finance.usedCarBusiness.servicingCost'
              ),
              field: 'servicingCost',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.servicingCost, ''),
              minWidth: 200
            }
          ]
        }
      ]
    },
    columnDefs6 () {
      return [
        {
          headerName: this.$t('dealer_finance.usedCarBusiness.title44'),
          field: 'tableTitle',
          children: [
            {
              headerName: this.$t('dealer_finance.usedCarBusiness.brand'),
              field: 'brand',
              rowSpan: (params) => {
                return params.brand === '二手车库存合计' ? 6 : 5
              },
              minWidth: 200
            },
            {
              headerName: this.$t(
                'dealer_finance.usedCarBusiness.inventoryDays'
              ),
              field: 'inventoryDays',
              minWidth: 200
            },
            {
              headerName: this.$t(
                'dealer_finance.usedCarBusiness.inventoryNumOfSets'
              ),
              field: 'inventoryNumOfSets',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.inventoryNumOfSets, ''),
              minWidth: 200
            },
            {
              headerName: this.$t(
                'dealer_finance.usedCarBusiness.inventoryAmount'
              ),
              field: 'inventoryAmount',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: (params) =>
                this.numberParser(params.data.inventoryAmount, ''),
              minWidth: 200
            }
          ]
        }
      ]
    }
  },
  watch: {
    editable (val) {
      const gridNos = ['1', '2', '3', '4', '5', '6']
      this.$nextTick(() => {
        gridNos.forEach(no => {
          this.$refs[`grid${no}`].redrawGrid(this[`columnDefs${no}`])
        })
      })
    },
    headInfo (val) {
      this.siteFlag = this.headInfo.siteFlag
    }
  },
  beforeMount () {
    this.gridOptions1.suppressRowTransform = true
    this.gridOptions2.suppressRowTransform = true
    this.gridOptions3.suppressRowTransform = true
    this.gridOptions4.suppressRowTransform = true
    this.gridOptions5.suppressRowTransform = true
    this.gridOptions6.suppressRowTransform = true
  },
  methods: {
    numberParser (val, defaultVal) {
      // console.log(val)
      return val
        ? this.$utils.numberFormat(val, { thousand: true, toFixed: 2 })
        : defaultVal
    },
    handleDataSearch () {
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })

      Axios.all([
        this.$axios.post(BASEURL.list1, { filter: [{ left: 'head.id', operate: '=', right: this.detailData.id }] }),
        this.$axios.post(BASEURL.list2, { filter: [{ left: 'head.id', operate: '=', right: this.detailData.id }] }),
        this.$axios.post(BASEURL.list3, { filter: [{ left: 'head.id', operate: '=', right: this.detailData.id }] }),
        this.$axios.post(BASEURL.list5, { filter: [{ left: 'head.id', operate: '=', right: this.detailData.id }] }),
        this.$axios.post(BASEURL.list6, { filter: [{ left: 'head.id', operate: '=', right: this.detailData.id }] })
      ])
        .then(
          Axios.spread((resp1, resp2, resp3, resp4, resp5) => {
            const data1 = resp1.data.list
            const data2 = resp2.data.list
            const data3 = resp3.data.list
            const data4 = resp3.data.list
            const data5 = resp4.data.list
            const data6 = resp5.data.list

            this.tableData1 = [
              {
                carType: 'L550',
                carAge: '0 - 1年'
              },
              {
                carType: 'L550',
                carAge: '1 - 3年'
              },
              {
                carType: 'L550',
                carAge: '3 - 5年'
              },
              {
                carType: 'L550',
                carAge: '5年以上'
              },
              {
                carType: 'L538',
                carAge: '0 - 1年'
              },
              {
                carType: 'L538',
                carAge: '1 - 3年'
              },
              {
                carType: 'L538',
                carAge: '3 - 5年'
              },
              {
                carType: 'L538',
                carAge: '5年以上'
              },
              {
                carType: 'L551',
                carAge: '0 - 1年'
              },
              {
                carType: 'L551',
                carAge: '1 - 3年'
              },
              {
                carType: 'L551',
                carAge: '3 - 5年'
              },
              {
                carType: 'L551',
                carAge: '5年以上'
              },
              {
                carType: 'Defender',
                carAge: '0 - 1年'
              },
              {
                carType: 'Defender',
                carAge: '1 - 3年'
              },
              {
                carType: 'Defender',
                carAge: '3 - 5年'
              },
              {
                carType: 'Defender',
                carAge: '5年以上'
              },
              {
                carType: 'New Discovery',
                carAge: '0 - 1年'
              },
              {
                carType: 'New Discovery',
                carAge: '1 - 3年'
              },
              {
                carType: 'New Discovery',
                carAge: '3 - 5年'
              },
              {
                carType: 'New Discovery',
                carAge: '5年以上'
              },
              {
                carType: 'Evoque Convertible',
                carAge: '0 - 1年'
              },
              {
                carType: 'Evoque Convertible',
                carAge: '1 - 3年'
              },
              {
                carType: 'Evoque Convertible',
                carAge: '3 - 5年'
              },
              {
                carType: 'Evoque Convertible',
                carAge: '5年以上'
              },
              {
                carType: 'Velar',
                carAge: '0 - 1年'
              },
              {
                carType: 'Velar',
                carAge: '1 - 3年'
              },
              {
                carType: 'Velar',
                carAge: '3 - 5年'
              },
              {
                carType: 'Velar',
                carAge: '5年以上'
              },
              {
                carType: 'RRS',
                carAge: '0 - 1年'
              },
              {
                carType: 'RRS',
                carAge: '1 - 3年'
              },
              {
                carType: 'RRS',
                carAge: '3 - 5年'
              },
              {
                carType: 'RRS',
                carAge: '5年以上'
              },
              {
                carType: 'RR',
                carAge: '0 - 1年'
              },
              {
                carType: 'RR',
                carAge: '1 - 3年'
              },
              {
                carType: 'RR',
                carAge: '3 - 5年'
              },
              {
                carType: 'RR',
                carAge: '5年以上'
              },
              {
                carType: 'E-PACE',
                carAge: '0 - 1年'
              },
              {
                carType: 'E-PACE',
                carAge: '1 - 3年'
              },
              {
                carType: 'E-PACE',
                carAge: '3 - 5年'
              },
              {
                carType: 'E-PACE',
                carAge: '5年以上'
              },
              {
                carType: 'XFL',
                carAge: '0 - 1年'
              },
              {
                carType: 'XFL',
                carAge: '1 - 3年'
              },
              {
                carType: 'XFL',
                carAge: '3 - 5年'
              },
              {
                carType: 'XFL',
                carAge: '5年以上'
              },
              {
                carType: 'XEL',
                carAge: '0 - 1年'
              },
              {
                carType: 'XEL',
                carAge: '1 - 3年'
              },
              {
                carType: 'XEL',
                carAge: '3 - 5年'
              },
              {
                carType: 'XEL',
                carAge: '5年以上'
              },
              {
                carType: 'XE',
                carAge: '0 - 1年'
              },
              {
                carType: 'XE',
                carAge: '1 - 3年'
              },
              {
                carType: 'XE',
                carAge: '3 - 5年'
              },
              {
                carType: 'XE',
                carAge: '5年以上'
              },
              {
                carType: 'XF',
                carAge: '0 - 1年'
              },
              {
                carType: 'XF',
                carAge: '1 - 3年'
              },
              {
                carType: 'XF',
                carAge: '3 - 5年'
              },
              {
                carType: 'XF',
                carAge: '5年以上'
              },
              {
                carType: 'XJ',
                carAge: '0 - 1年'
              },
              {
                carType: 'XJ',
                carAge: '1 - 3年'
              },
              {
                carType: 'XJ',
                carAge: '3 - 5年'
              },
              {
                carType: 'XJ',
                carAge: '5年以上'
              },
              {
                carType: 'F-Type',
                carAge: '0 - 1年'
              },
              {
                carType: 'F-Type',
                carAge: '1 - 3年'
              },
              {
                carType: 'F-Type',
                carAge: '3 - 5年'
              },
              {
                carType: 'F-Type',
                carAge: '5年以上'
              },
              {
                carType: 'F-Pace',
                carAge: '0 - 1年'
              },
              {
                carType: 'F-Pace',
                carAge: '1 - 3年'
              },
              {
                carType: 'F-Pace',
                carAge: '3 - 5年'
              },
              {
                carType: 'F-Pace',
                carAge: '5年以上'
              },
              {
                carType: 'I-Pace',
                carAge: '0 - 1年'
              },
              {
                carType: 'I-Pace',
                carAge: '1 - 3年'
              },
              {
                carType: 'I-Pace',
                carAge: '3 - 5年'
              },
              {
                carType: 'I-Pace',
                carAge: '5年以上'
              },
              {
                carType: '除试驾车/代步车以外的认证二手车小计',
                carAge: '0 - 1年'
              },
              {
                carType: '除试驾车/代步车以外的认证二手车小计',
                carAge: '1 - 3年'
              },
              {
                carType: '除试驾车/代步车以外的认证二手车小计',
                carAge: '3 - 5年'
              },
              {
                carType: '除试驾车/代步车以外的认证二手车小计',
                carAge: '5年以上'
              },
              {
                carType: '除试驾车/代步车以外的认证二手车小计',
                carAge: '合计'
              }
            ]
            this.tableData2 = [
              {
                carType: 'L550'
              },
              {
                carType: 'L538'
              },
              {
                carType: 'L551'
              },
              {
                carType: 'Defender'
              },
              {
                carType: 'New Discovery'
              },
              {
                carType: 'Evoque Convertible'
              },
              {
                carType: 'Velar'
              },
              {
                carType: 'RRS'
              },
              {
                carType: 'RR'
              },
              {
                carType: 'E-PACE'
              },
              {
                carType: 'XFL'
              },
              {
                carType: 'XEL'
              },
              {
                carType: 'XE'
              },
              {
                carType: 'XF'
              },
              {
                carType: 'XJ'
              },
              {
                carType: 'F-Type'
              },
              {
                carType: 'F-Pace'
              },
              {
                carType: 'I-Pace'
              },
              {
                carType: '试驾车小计'
              }
            ]
            this.tableData3 = [
              {
                brand: '路虎品牌合计',
                type: '4.1.3'
              },
              {
                brand: '捷豹品牌合计',
                type: '4.1.3'
              },
              {
                brand: '代步车小计',
                type: '4.1.3'
              }
            ]
            this.tableData4 = [
              {
                brand: '路虎品牌合计',
                type: '4.2.1'
              },
              {
                brand: '捷豹品牌合计',
                type: '4.2.1'
              },
              {
                brand: '其他品牌',
                type: '4.2.1'
              },
              {
                brand: '合计',
                type: '4.2.1'
              }
            ]
            this.tableData5 = [
              {
                brand: '认证二手车-路虎'
              },
              {
                brand: '认证二手车-捷豹'
              },
              {
                brand: '非认证二手车-路虎'
              },
              {
                brand: '非认证二手车-捷豹'
              },
              {
                brand: '非认证二手车-其他品牌'
              },
              {
                brand: '合计'
              }
            ]
            this.tableData6 = [
              {
                brand: 'L550',
                inventoryDays: '0-30天'
              },
              {
                brand: 'L550',
                inventoryDays: '31-60天'
              },
              {
                brand: 'L550',
                inventoryDays: '61-90天'
              },
              {
                brand: 'L550',
                inventoryDays: '91-180天'
              },
              {
                brand: 'L550',
                inventoryDays: '大于180天'
              },
              {
                brand: 'L538',
                inventoryDays: '0-30天'
              },
              {
                brand: 'L538',
                inventoryDays: '31-60天'
              },
              {
                brand: 'L538',
                inventoryDays: '61-90天'
              },
              {
                brand: 'L538',
                inventoryDays: '91-180天'
              },
              {
                brand: 'L538',
                inventoryDays: '大于180天'
              },
              {
                brand: 'L551',
                inventoryDays: '0-30天'
              },
              {
                brand: 'L551',
                inventoryDays: '31-60天'
              },
              {
                brand: 'L551',
                inventoryDays: '61-90天'
              },
              {
                brand: 'L551',
                inventoryDays: '91-180天'
              },
              {
                brand: 'L551',
                inventoryDays: '大于180天'
              },
              {
                brand: 'Defender',
                inventoryDays: '0-30天'
              },
              {
                brand: 'Defender',
                inventoryDays: '31-60天'
              },
              {
                brand: 'Defender',
                inventoryDays: '61-90天'
              },
              {
                brand: 'Defender',
                inventoryDays: '91-180天'
              },
              {
                brand: 'Defender',
                inventoryDays: '大于180天'
              },
              {
                brand: 'New Discovery',
                inventoryDays: '0-30天'
              },
              {
                brand: 'New Discovery',
                inventoryDays: '31-60天'
              },
              {
                brand: 'New Discovery',
                inventoryDays: '61-90天'
              },
              {
                brand: 'New Discovery',
                inventoryDays: '91-180天'
              },
              {
                brand: 'New Discovery',
                inventoryDays: '大于180天'
              },
              {
                brand: 'Evoque Convertible',
                inventoryDays: '0-30天'
              },
              {
                brand: 'Evoque Convertible',
                inventoryDays: '31-60天'
              },
              {
                brand: 'Evoque Convertible',
                inventoryDays: '61-90天'
              },
              {
                brand: 'Evoque Convertible',
                inventoryDays: '91-180天'
              },
              {
                brand: 'Evoque Convertible',
                inventoryDays: '大于180天'
              },
              {
                brand: 'Velar',
                inventoryDays: '0-30天'
              },
              {
                brand: 'Velar',
                inventoryDays: '31-60天'
              },
              {
                brand: 'Velar',
                inventoryDays: '61-90天'
              },
              {
                brand: 'Velar',
                inventoryDays: '91-180天'
              },
              {
                brand: 'Velar',
                inventoryDays: '大于180天'
              },
              {
                brand: 'RRS',
                inventoryDays: '0-30天'
              },
              {
                brand: 'RRS',
                inventoryDays: '31-60天'
              },
              {
                brand: 'RRS',
                inventoryDays: '61-90天'
              },
              {
                brand: 'RRS',
                inventoryDays: '91-180天'
              },
              {
                brand: 'RRS',
                inventoryDays: '大于180天'
              },
              {
                brand: 'RR',
                inventoryDays: '0-30天'
              },
              {
                brand: 'RR',
                inventoryDays: '31-60天'
              },
              {
                brand: 'RR',
                inventoryDays: '61-90天'
              },
              {
                brand: 'RR',
                inventoryDays: '91-180天'
              },
              {
                brand: 'RR',
                inventoryDays: '大于180天'
              },
              {
                brand: 'E-PACE',
                inventoryDays: '0-30天'
              },
              {
                brand: 'E-PACE',
                inventoryDays: '31-60天'
              },
              {
                brand: 'E-PACE',
                inventoryDays: '61-90天'
              },
              {
                brand: 'E-PACE',
                inventoryDays: '91-180天'
              },
              {
                brand: 'E-PACE',
                inventoryDays: '大于180天'
              },
              {
                brand: 'XFL',
                inventoryDays: '0-30天'
              },
              {
                brand: 'XFL',
                inventoryDays: '31-60天'
              },
              {
                brand: 'XFL',
                inventoryDays: '61-90天'
              },
              {
                brand: 'XFL',
                inventoryDays: '91-180天'
              },
              {
                brand: 'XFL',
                inventoryDays: '大于180天'
              },
              {
                brand: 'XEL',
                inventoryDays: '0-30天'
              },
              {
                brand: 'XEL',
                inventoryDays: '31-60天'
              },
              {
                brand: 'XEL',
                inventoryDays: '61-90天'
              },
              {
                brand: 'XEL',
                inventoryDays: '91-180天'
              },
              {
                brand: 'XEL',
                inventoryDays: '大于180天'
              },
              {
                brand: 'XE',
                inventoryDays: '0-30天'
              },
              {
                brand: 'XE',
                inventoryDays: '31-60天'
              },
              {
                brand: 'XE',
                inventoryDays: '61-90天'
              },
              {
                brand: 'XE',
                inventoryDays: '91-180天'
              },
              {
                brand: 'XE',
                inventoryDays: '大于180天'
              },
              {
                brand: 'XF',
                inventoryDays: '0-30天'
              },
              {
                brand: 'XF',
                inventoryDays: '31-60天'
              },
              {
                brand: 'XF',
                inventoryDays: '61-90天'
              },
              {
                brand: 'XF',
                inventoryDays: '91-180天'
              },
              {
                brand: 'XF',
                inventoryDays: '大于180天'
              },
              {
                brand: 'XJ',
                inventoryDays: '0-30天'
              },
              {
                brand: 'XJ',
                inventoryDays: '31-60天'
              },
              {
                brand: 'XJ',
                inventoryDays: '61-90天'
              },
              {
                brand: 'XJ',
                inventoryDays: '91-180天'
              },
              {
                brand: 'XJ',
                inventoryDays: '大于180天'
              },

              {
                brand: 'F-Type',
                inventoryDays: '0-30天'
              },
              {
                brand: 'F-Type',
                inventoryDays: '31-60天'
              },
              {
                brand: 'F-Type',
                inventoryDays: '61-90天'
              },
              {
                brand: 'F-Type',
                inventoryDays: '91-180天'
              },
              {
                brand: 'F-Type',
                inventoryDays: '大于180天'
              },

              {
                brand: 'F-Pace',
                inventoryDays: '0-30天'
              },
              {
                brand: 'F-Pace',
                inventoryDays: '31-60天'
              },
              {
                brand: 'F-Pace',
                inventoryDays: '61-90天'
              },
              {
                brand: 'F-Pace',
                inventoryDays: '91-180天'
              },
              {
                brand: 'F-Pace',
                inventoryDays: '大于180天'
              },

              {
                brand: 'I-Pace',
                inventoryDays: '0-30天'
              },
              {
                brand: 'I-Pace',
                inventoryDays: '31-60天'
              },
              {
                brand: 'I-Pace',
                inventoryDays: '61-90天'
              },
              {
                brand: 'I-Pace',
                inventoryDays: '91-180天'
              },
              {
                brand: 'I-Pace',
                inventoryDays: '大于180天'
              },

              {
                brand: '非捷豹路虎品牌',
                inventoryDays: '0-30天'
              },
              {
                brand: '非捷豹路虎品牌',
                inventoryDays: '31-60天'
              },
              {
                brand: '非捷豹路虎品牌',
                inventoryDays: '61-90天'
              },
              {
                brand: '非捷豹路虎品牌',
                inventoryDays: '91-180天'
              },
              {
                brand: '非捷豹路虎品牌',
                inventoryDays: '大于180天'
              },
              {
                brand: '二手车库存合计',
                inventoryDays: '0-30天'
              },
              {
                brand: '二手车库存合计',
                inventoryDays: '31-60天'
              },
              {
                brand: '二手车库存合计',
                inventoryDays: '61-90天'
              },
              {
                brand: '二手车库存合计',
                inventoryDays: '91-180天'
              },
              {
                brand: '二手车库存合计',
                inventoryDays: '大于180天'
              },
              {
                brand: '二手车库存合计',
                inventoryDays: '合计'
              }
            ]

            let oData
            let tableData = this.$_.cloneDeep(this.tableData1)
            for (const i in tableData) {
              oData = data1.find((rsData) => {
                return (
                  tableData[i].carType === rsData.carType &&
                  tableData[i].carAge === rsData.carAge
                )
              })
              if (oData) {
                this.$set(this.tableData1, i, oData)
              }
            }

            tableData = this.$_.cloneDeep(this.tableData2)
            for (const i in tableData) {
              oData = data2.find((rsData) => {
                return tableData[i].carType === rsData.carType
              })
              if (oData) {
                this.$set(this.tableData2, i, oData)
              }
            }

            tableData = this.$_.cloneDeep(this.tableData3)
            for (const i in tableData) {
              oData = data3.find((rsData) => {
                return (
                  tableData[i].brand === rsData.brand &&
                  tableData[i].type === rsData.type
                )
              })
              if (oData) {
                this.$set(this.tableData3, i, oData)
              }
            }

            tableData = this.$_.cloneDeep(this.tableData4)
            for (const i in tableData) {
              oData = data4.find((rsData) => {
                return (
                  tableData[i].brand === rsData.brand &&
                  tableData[i].type === rsData.type
                )
              })
              if (oData) {
                this.$set(this.tableData4, i, oData)
              }
            }

            tableData = this.$_.cloneDeep(this.tableData5)
            for (const i in tableData) {
              oData = data5.find((rsData) => {
                return (
                  tableData[i].brand === rsData.brand &&
                  tableData[i].type === rsData.type
                )
              })
              if (oData) {
                this.$set(this.tableData5, i, oData)
              }
            }

            tableData = this.$_.cloneDeep(this.tableData6)
            for (const i in tableData) {
              oData = data6.find((rsData) => {
                return (
                  tableData[i].brand === rsData.brand &&
                  tableData[i].inventoryDays === rsData.inventoryDays
                )
              })
              if (oData) {
                this.$set(this.tableData6, i, oData)
              }
            }
          })
        )
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleSaveClick (no) {
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      const tableData = this['tableData' + no]

      if (no === '1') {
        for (let i = 0; i < tableData.length; i++) {
          if (isNaN(tableData[i].numOfSets)) {
            tableData[i].numOfSets = 0
          }
          if (isNaN(tableData[i].income)) {
            tableData[i].income = 0
          }
          if (isNaN(tableData[i].grossProfit)) {
            tableData[i].grossProfit = 0
          }
        }

        let numOfSets1 = 0
        let numOfSets2 = 0
        let numOfSets3 = 0
        let numOfSets4 = 0
        let numOfSets5 = 0
        let income1 = 0
        let income2 = 0
        let income3 = 0
        let income4 = 0
        let income5 = 0
        let grossProfit1 = 0
        let grossProfit2 = 0
        let grossProfit3 = 0
        let grossProfit4 = 0
        let grossProfit5 = 0
        for (let i = 0; i < 18; i++) {
          numOfSets1 += tableData[i * 4].numOfSets
          numOfSets2 += tableData[i * 4 + 1].numOfSets
          numOfSets3 += tableData[i * 4 + 2].numOfSets
          numOfSets4 += tableData[i * 4 + 3].numOfSets
          income1 += tableData[i * 4].income
          income2 += tableData[i * 4 + 1].income
          income3 += tableData[i * 4 + 2].income
          income4 += tableData[i * 4 + 3].income
          grossProfit1 += tableData[i * 4].grossProfit
          grossProfit2 += tableData[i * 4 + 1].grossProfit
          grossProfit3 += tableData[i * 4 + 2].grossProfit
          grossProfit4 += tableData[i * 4 + 3].grossProfit
        }

        numOfSets5 = numOfSets1 + numOfSets2 + numOfSets3 + numOfSets4
        income5 = income1 + income2 + income3 + income4
        grossProfit5 = grossProfit1 + grossProfit2 + grossProfit3 + grossProfit4

        tableData[72].numOfSets = numOfSets1
        tableData[72].income = income1
        tableData[72].grossProfit = grossProfit1

        tableData[73].numOfSets = numOfSets2
        tableData[73].income = income2
        tableData[73].grossProfit = grossProfit2

        tableData[74].numOfSets = numOfSets3
        tableData[74].income = income3
        tableData[74].grossProfit = grossProfit3

        tableData[75].numOfSets = numOfSets4
        tableData[75].income = income4
        tableData[75].grossProfit = grossProfit4

        tableData[76].numOfSets = numOfSets5
        tableData[76].income = income5
        tableData[76].grossProfit = grossProfit5
      }

      if (no === '2') {
        for (let i = 0; i < tableData.length; i++) {
          if (isNaN(tableData[i].numOfSets)) {
            tableData[i].numOfSets = 0
          }
          if (isNaN(tableData[i].income)) {
            tableData[i].income = 0
          }
          if (isNaN(tableData[i].grossProfit)) {
            tableData[i].grossProfit = 0
          }
        }

        let numOfSets = 0
        let income = 0
        let grossProfit = 0

        for (let i = 0; i < 18; i++) {
          numOfSets += tableData[i].numOfSets
          income += tableData[i].income
          grossProfit += tableData[i].grossProfit
        }

        tableData[18].numOfSets = numOfSets
        tableData[18].income = income
        tableData[18].grossProfit = grossProfit
      }

      if (no === '3') {
        for (let i = 0; i < tableData.length; i++) {
          if (isNaN(tableData[i].numOfSets)) {
            tableData[i].numOfSets = 0
          }
          if (isNaN(tableData[i].income)) {
            tableData[i].income = 0
          }
          if (isNaN(tableData[i].grossProfit)) {
            tableData[i].grossProfit = 0
          }
        }

        let numOfSets = 0
        let income = 0
        let grossProfit = 0

        for (let i = 0; i < 2; i++) {
          numOfSets += tableData[i].numOfSets
          income += tableData[i].income
          grossProfit += tableData[i].grossProfit
        }

        tableData[2].numOfSets = numOfSets
        tableData[2].income = income
        tableData[2].grossProfit = grossProfit
      }

      if (no === '4') {
        for (let i = 0; i < tableData.length; i++) {
          if (isNaN(tableData[i].numOfSets)) {
            tableData[i].numOfSets = 0
          }
          if (isNaN(tableData[i].income)) {
            tableData[i].income = 0
          }
          if (isNaN(tableData[i].grossProfit)) {
            tableData[i].grossProfit = 0
          }
        }

        let numOfSets = 0
        let income = 0
        let grossProfit = 0

        for (let i = 0; i < 3; i++) {
          numOfSets += tableData[i].numOfSets
          income += tableData[i].income
          grossProfit += tableData[i].grossProfit
        }

        tableData[3].numOfSets = numOfSets
        tableData[3].income = income
        tableData[3].grossProfit = grossProfit
      }

      if (no === '5') {
        for (let i = 0; i < tableData.length; i++) {
          if (isNaN(tableData[i].insuranceNumOfSets)) {
            tableData[i].insuranceNumOfSets = 0
          }
          if (isNaN(tableData[i].insuranceGrossProfit)) {
            tableData[i].insuranceGrossProfit = 0
          }
          if (isNaN(tableData[i].financeNumOfSets)) {
            tableData[i].financeNumOfSets = 0
          }
          if (isNaN(tableData[i].financeGrossProfit)) {
            tableData[i].financeGrossProfit = 0
          }
          if (isNaN(tableData[i].boutiquesSaleIncome)) {
            tableData[i].boutiquesSaleIncome = 0
          }
          if (isNaN(tableData[i].boutiqueSaleCost)) {
            tableData[i].boutiqueSaleCost = 0
          }
          if (isNaN(tableData[i].otherIncome)) {
            tableData[i].otherIncome = 0
          }
          if (isNaN(tableData[i].otherCost)) {
            tableData[i].otherCost = 0
          }
          if (isNaN(tableData[i].servicingCost)) {
            tableData[i].servicingCost = 0
          }
        }

        let insuranceNumOfSets = 0
        let insuranceGrossProfit = 0
        let financeNumOfSets = 0
        let financeGrossProfit = 0
        let boutiquesSaleIncome = 0
        let boutiqueSaleCost = 0
        let otherIncome = 0
        let otherCost = 0
        let servicingCost = 0

        for (let i = 0; i < 5; i++) {
          insuranceNumOfSets += tableData[i].insuranceNumOfSets
          insuranceGrossProfit += tableData[i].insuranceGrossProfit
          financeNumOfSets += tableData[i].financeNumOfSets
          financeGrossProfit += tableData[i].financeGrossProfit
          boutiquesSaleIncome += tableData[i].boutiquesSaleIncome
          boutiqueSaleCost += tableData[i].boutiqueSaleCost
          otherIncome += tableData[i].otherIncome
          otherCost += tableData[i].otherCost
          servicingCost += tableData[i].servicingCost
        }

        tableData[5].insuranceNumOfSets = insuranceNumOfSets
        tableData[5].insuranceGrossProfit = insuranceGrossProfit
        tableData[5].financeNumOfSets = financeNumOfSets
        tableData[5].financeGrossProfit = financeGrossProfit
        tableData[5].boutiquesSaleIncome = boutiquesSaleIncome
        tableData[5].boutiqueSaleCost = boutiqueSaleCost
        tableData[5].otherIncome = otherIncome
        tableData[5].otherCost = otherCost
        tableData[5].servicingCost = servicingCost
      }

      if (no === '6') {
        for (let i = 0; i < tableData.length; i++) {
          if (isNaN(tableData[i].inventoryNumOfSets)) {
            tableData[i].inventoryNumOfSets = 0
          }
          if (isNaN(tableData[i].inventoryAmount)) {
            tableData[i].inventoryAmount = 0
          }
        }

        let inventoryNumOfSets1 = 0
        let inventoryNumOfSets2 = 0
        let inventoryNumOfSets3 = 0
        let inventoryNumOfSets4 = 0
        let inventoryNumOfSets5 = 0
        let inventoryNumOfSets = 0
        let inventoryAmount1 = 0
        let inventoryAmount2 = 0
        let inventoryAmount3 = 0
        let inventoryAmount4 = 0
        let inventoryAmount5 = 0
        let inventoryAmount = 0

        for (let i = 0; i < 19; i++) {
          inventoryNumOfSets1 += tableData[i * 5].inventoryNumOfSets
          inventoryNumOfSets2 += tableData[i * 5 + 1].inventoryNumOfSets
          inventoryNumOfSets3 += tableData[i * 5 + 2].inventoryNumOfSets
          inventoryNumOfSets4 += tableData[i * 5 + 3].inventoryNumOfSets
          inventoryNumOfSets5 += tableData[i * 5 + 4].inventoryNumOfSets
          inventoryAmount1 += tableData[i * 5].inventoryAmount
          inventoryAmount2 += tableData[i * 5 + 1].inventoryAmount
          inventoryAmount3 += tableData[i * 5 + 2].inventoryAmount
          inventoryAmount4 += tableData[i * 5 + 3].inventoryAmount
          inventoryAmount5 += tableData[i * 5 + 4].inventoryAmount
        }

        inventoryNumOfSets = inventoryNumOfSets1 + inventoryNumOfSets2 + inventoryNumOfSets3 + inventoryNumOfSets4 + inventoryNumOfSets5
        inventoryAmount = inventoryAmount1 + inventoryAmount2 + inventoryAmount3 + inventoryAmount4 + inventoryAmount5

        tableData[95].inventoryNumOfSets = inventoryNumOfSets1
        tableData[96].inventoryNumOfSets = inventoryNumOfSets2
        tableData[97].inventoryNumOfSets = inventoryNumOfSets3
        tableData[98].inventoryNumOfSets = inventoryNumOfSets4
        tableData[99].inventoryNumOfSets = inventoryNumOfSets5
        tableData[100].inventoryNumOfSets = inventoryNumOfSets

        tableData[95].inventoryAmount = inventoryAmount1
        tableData[96].inventoryAmount = inventoryAmount2
        tableData[97].inventoryAmount = inventoryAmount3
        tableData[98].inventoryAmount = inventoryAmount4
        tableData[99].inventoryAmount = inventoryAmount5
        tableData[100].inventoryAmount = inventoryAmount
      }

      // console.log(tableData)

      let url = BASEURL[`update${no}`]

      this.$axios
        .post(url, tableData)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
          this.handleDataSearch()
        })
        .finally(() => {
          loadingFlag.close()
        })
    }
  }
}
</script>
<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>
